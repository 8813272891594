var isMobile = new Object();

isMobile.Android = function () {

	return navigator.userAgent.match(/Android/i);

};



isMobile.BlackBerry = function () {

	return navigator.userAgent.match(/BlackBerry/i);

};

isMobile.iOS = function () {

	return navigator.userAgent.match(/iPhone|iPad|iPod/i);

};



isMobile.iPad = function () {

	return navigator.userAgent.match(/iPad/i);

};

isMobile.Opera = function () {

	return navigator.userAgent.match(/Opera Mini/i);

};

isMobile.Windows = function () {

	return navigator.userAgent.match(/IEMobile/i);

};



isMobile.Firefox = function () {

	return navigator.userAgent.match(/Firefox/gi);

};

isMobile.InternetExplorer = function () {

	return navigator.userAgent.match(/MSIE/gi);

};

isMobile.Opera = function () {

	return navigator.userAgent.match(/Opera/gi);

};

isMobile.Safari = function () {

	return navigator.userAgent.match(/Safari/gi);

};

isMobile.any = function () {

	return (

		isMobile.Android() ||

		isMobile.BlackBerry() ||

		isMobile.iOS() ||

		isMobile.Opera() ||

		isMobile.Windows()

	);

};

if (isMobile.any() && isMobile.iPad() == null) {

	//return;

}



var ua = window.navigator.userAgent;

var is_ie = /MSIE|Trident/.test(ua);



/*++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/



var winWT = $(window).innerWidth();

var winHT = $(window).innerHeight();



$(window).on("load", function() {

	$("#loader").fadeOut(500);

});



$(document).ready(function () {

	//alert( winWT + "&&" + winHT);



	/* $("body").niceScroll({

		cursorcolor:		"#2ecc71",

		cursorwidth: 		"0px",

		cursorborder: 		"0px solid #000",

		scrollspeed: 		100,

		autohidemode: 		true,

		background: 		'#ddd',

		hidecursordelay: 	400,

		cursorfixedheight: 	false,

		cursorminheight: 	20,

		enablekeyboard: 	true,

		horizrailenabled: 	true,

		bouncescroll: 		true,

		smoothscroll: 		true,

		iframeautoresize: 	true,

		touchbehavior: 		false,

		zindex: 999

	}); */

	

	var disableScrolling = function(){

		var x=window.scrollX;

		var y=window.scrollY;

		window.onscroll=function(){window.scrollTo(x, y);};

	}

	var enableScrolling = function(){

		window.onscroll=function(){};

	}

	function onResize(){

		if(isMobile.any() && $("input").is(":focus")==true){

			return;

		}

		winWT = $(window).innerWidth();

		winHT = $(window).innerHeight();

		//$("section").css({minHeight:winHT});



		if (winWT <= 1023) {

			$(".fj-home-banner").css({height:winHT});

		}

		if (winWT <= 601) {

			$(".fj-inner-banner").css({height:winHT});

		}

	}

	

	$(window).resize(onResize)

	onResize();



	if(winWT < 601){

		function onOrientationChange() {

			if (window.orientation == 90 || window.orientation == -90) {

				$(".rotate-msg").css("display", "block");

			} else {

				$(".rotate-msg").css("display", "none");

			}

		}

		window.addEventListener("orientationchange", onOrientationChange, false);

		onOrientationChange();

	}



	if (winWT <= 1025) {

		$('.fj-navigation ul li a', '.fj-top-nav a').click(function () {

			$('.menu').trigger('click');

		});

		gsap.set(".fj-navigation ul > li", { opacity: 0, x:50, ease:Power2.easeInOut });	



    }else{

		gsap.set(".fj-navigation ul > li", { opacity: 1, x:0});

	}



	if (winWT >= 768) {

		//handleMarquee();

	}



	



	if ($(".fj-header").hasClass("fj-white-header")) {

		$('#logo').attr('src', 'https://www.findyourdreamteam.com/assets/images/logo-white.png');

	}

	

	$('.menu').on('click',function () {

		$(this).toggleClass("toggle");

		if ($('.menu').hasClass("toggle")) {

			$('body').unbind('click')

			//$('body').addClass("no-scroll")

			//disableScrolling();

			//$(".nav-overlay").fadeIn(100);

			gsap.to(".fj-navigation", {duration: 0.1, right:"0%",  ease:Power0.easeNone})

			gsap.to(".fj-navigation ul > li", {duration: 0.2,opacity:1, x:0, stagger: {

				amount: 0.5, 

				grid: 'auto', 

				ease: '"elastic.in"',

				stagger: 0.1

			}});

			

		}else {

			//enableScrolling();

			//$('body').removeClass("no-scroll")

			//$(".nav-overlay").fadeOut(100);

			gsap.to(".fj-navigation", {duration: 0.1, right:"-100%",  ease:Power0.easeNone})

			gsap.to(".fj-navigation ul > li", {duration: 0.2, opacity:0, x:50, stagger: {

				amount: 0.5, 

				grid: 'auto', 

				ease: '"elastic.in"',

				stagger: 0.1

			}});

		}

	});



	var navPosition = "down";

	function headerWipe(){

		if($('.menu').hasClass("toggle")){

			return;

		}

		if ($(this).scrollTop() > 10 && navPosition == "down") {

			navPosition = "up";

			$("header").addClass("sticky");

			//$("#navigation").addClass("sticky");



			$('#logo').attr('src', 'https://www.findyourdreamteam.com/assets/images/logo-black.png');

		}

		else if($(this).scrollTop() < 10 && navPosition == "up"){

			navPosition = "down";

			$("header").removeClass("sticky");

			//$("#navigation").removeClass("sticky");



			if ($(".fj-header").hasClass("fj-white-header")) {

				$('#logo').attr('src', 'https://www.findyourdreamteam.com/assets/images/logo-white.png');

			}else{

				$('#logo').attr('src', 'https://www.findyourdreamteam.com/assets/images/logo-black.png');

			}

		}

	}

	//headerWipe();

	$(window).bind('scroll', function () {

		//if (winWT >= 1025) {

			headerWipe();

		//}

	});



	$(function() {

		AOS.init({once: true});

   });

   	/* $('ul.fj-user-menu-list').each(function() {
		var select = $(document.createElement('select')).insertBefore($(this).hide());
		$('>li a', this).each(function() {
			var a = $(this).click(function() {
				if ($(this).attr('target')==='_blank') {
					window.open(this.href);
				}
				else {
					window.location.href = this.href;
				}
			}),
			option = $(document.createElement('option')).appendTo(select).val(this.href).html($(this).html()).click(function() {
				a.click();
			});
		});
	}); */

	/* function handleMarquee(){

		var marquee = document.querySelectorAll('.ticker');

		var speed = 5;



		if(winWT < 1023){

			var speed = 1;

		}

		

		marquee.forEach(function(el){

			var container = el.querySelector('.inner');

			var content = el.querySelector('.inner > *');

			//Get total width

			var  elWidth = content.offsetWidth;

			

			//Duplicate content

			

			for (var i = 0; i < 1; i++) {

				var clone = content.cloneNode(true);

				container.appendChild(clone);

			}

			//container.appendChild(clone);

			

			var progress = 10;

			function loop(){

				progress = progress-speed;

				if(progress <= elWidth*-1) {progress=0;}

				container.style.transform = 'translateX(' + progress + 'px)';

				container.style.transform += 'skewX(' + speed*0.4 + 'deg)';

				window.requestAnimationFrame(loop);

			}

			loop();

		});

	}; */

	$('.ticker').marquee({
		speed: 100,
		gap:0,
		delayBeforeStart: 0,
		direction: 'left',
		duplicated: true,
		pauseOnHover: false
	});


	$('.ae-select-content').text($('.dropdown-menu > li.selected').text());
	var newOptions = $('.dropdown-menu > li');
	newOptions.click(function() {
    	$('.ae-select-content').text($(this).text());
    	$('.dropdown-menu > li').removeClass('selected');
    	$(this).addClass('selected');
	});

	var aeDropdown = $('.ae-dropdown');
	aeDropdown.click(function() {
    	$('.dropdown-menu').toggleClass('ae-hide');
	});



});